import React, { useState } from 'react'
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby"


const Navigation = ({mobileMenu}) => {

    const [submenuOpen, setSubMenuOpen] = useState(false)

    const SubMenuQuery = () => {
        return (
            <StaticQuery
              query={graphql`
              {
                allMarkdownRemark(filter: {frontmatter: {type: {eq: "category"}}},  sort: {fields: frontmatter___sortorder}) {
                  edges {
                    node {
                      frontmatter {
                        url
                        title
                      }
                    }
                  }
                }
              }
              `}
              render={data => (
                    
                <SubMenu data={data}/>
              )}
            />
          )
    }  

    //dont know if this is the best way to do this but running out of time
    const SubMenu = ({data}) => {
        return data.allMarkdownRemark.edges.map((e,i) => <li key={"submenu-"+ i}><Link to={`/${e.node.frontmatter.url}`}><span>{e.node.frontmatter.title}</span></Link></li>)
    }

    //The name is a bit excessive
    const handleSubMenuParentClick = () => {

        mobileMenu && setSubMenuOpen(!submenuOpen)

    }

    return (

        <nav> 
            
            <ul>
                <li className="first-item main-item">
                    <Link to='/' activeClassName="active"><span>Home</span></Link>
                </li>
                <li className="main-item">
                    <Link to='/about-us' activeClassName="active"><span>About Us</span></Link>
                </li>
                <li className={`has-submenu main-item ${submenuOpen ? 'sub-menu-open' : ''}`} >
                    <button onClick={() => handleSubMenuParentClick()}>Products 
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6.175" viewBox="0 0 10 6.175">
                            <path id="Icon_material-keyboard-arrow-down" data-name="Icon material-keyboard-arrow-down" d="M10.175,11.76,14,15.577l3.825-3.817L19,12.935l-5,5-5-5Z" transform="translate(-9 -11.76)" fill="#1B3F95"/>
                        </svg>
                    </button>
                    <ul className="sub-menu">
                        <SubMenuQuery />

                    </ul>
                </li>
                <li className="main-item">
                    <Link to='/applications' activeClassName="active"><span>Applications</span></Link>
                </li>
                <li className="main-item">
                    <Link to='/support' activeClassName="active"><span>Support</span></Link>
                </li>
                <li className="main-item">
                    <Link to='/contact-us' activeClassName="active"><span>Contact Us</span></Link>
                </li>

            </ul>
        </nav>
       
    )

}

export default Navigation